<template>

  <div>

    <section class="account-details px-0 py-0">

      <hr class="m-1" style="background-color: black;" />

      <div style="background-color: #E0E0E0;border-radius: 5px;padding: 5px;" class="row px-1 py-1 m-0 mb-2">

        <div style="text-align: center;" class="col-6">
          <div class="line-height-0">
            <span class="player-profile-details text-dark">Player profile details</span><br>
            <strong class="text-dark">{{ profile.first_name }} </strong><br>
          </div>

          <div class="line-height-0">
            <span class="player-profile-details text-dark">Account Number</span><br>
            <strong class="text-dark">{{ profile.msisdn }}</strong><br>
          </div>
          <br />

          <div class="account ">
            <span class="text-dark">Account Balance</span><br>
            <strong class="text-dark">Ksh. <span v-text="bal"></span></strong>
          </div>


        </div>

        <div style="text-align: center;" class="col-6">

          <input
            :style="{ backgroundColor: inputBackground, outline: 'none', borderRadius: '5px', padding: '5px', color: 'black', border: '1px solid #1c2243' }"
            type="text" name="amount" placeholder="100" aria-label="Amount (to the nearest KES)"
            v-model="withdraw_amount" />
          <br />
          <small style="color: red;" class="mb-3 mt-3">Withdraw From Ksh. 100 - Ksh. 70,000</small>
          <br />
          <button style="background-color: #1c2243;color: white; border-radius: 3px;" @click="withdraw"
            v-bind:class="loading" class="btn">
            <span style="color: white;">Withdraw</span>
          </button>
          <br />
          <br />
          <div class="account">
            <span class="text-dark">Bonus</span><br>
            <strong class="text-dark">Ksh. {{ bonus }}</strong>
          </div>
        </div>

      </div>

    </section>
    <!-- <div class="text-yellow text-center matches-title mb-1">
      Withdraw <br>
      <span class="text-dark">Withdraw funds from your Sportycrash account</span>
    </div>
    <section class="px-2 mb-3">
      <div class="form-wrapper mb-0">
        <div class="input-group mb-0">
          <div class="input-group-prepend">
            <span class="input-group-text text-dark">Ksh</span>
          </div>
          <input type="text" class="form-control" name="amount" placeholder="100"
            aria-label="Amount (to the nearest KES)" v-model="withdraw_amount">
          <div class="input-group-append">
            <span class="input-group-text"></span>
          </div>
        </div>
        <small class="text-dark mb-3 mt-3">Minimum Ksh. 100 Maximum Ksh. 70,000</small>
        <button @click="withdraw" v-bind:class="loading" class="join-button py-2 form-control"
          style="color: black !important;">WITHDRAW</button>
      </div>
    </section> -->
    <hr class="m-1" style="background-color: black;" />

    <div style="color: #1c2243;" class="text-center matches-title mb-1">
      Deposit
      <br>
    </div>
    <section class="px-2 mb-3">
      <div class="form-wrapper mb-0">
        <router-link to="/deposit" class="join-button py-2 form-control" style="color: white !important;">
          DEPOSIT TO ACCOUNT
        </router-link>
      </div>
    </section>

    <hr class="m-1" style="background-color: black;" />

    <div style="background-color: #E0E0E0;border-radius: 5px;color:#1c2243;" class="text-center matches-title mb-1">
      Refer A friend and Earn <br>
      <span class="text-dark">Many gifts awaiting for you if you share the below link with your friends</span>
    </div>

    <section style="background-color: #E0E0E0;border-radius: 5px;padding: 5px;" class="px-2 mb-3">

      <div class="social-icons d-flex justify-content-around px-2 text-center mb-2">

        <a href="https://www.facebook.com/SportycrashKE/" target="_blank" title="Tweet"
          onclick="window.open('https://www.facebook.com/SportycrashKE/' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
          <img loading="lazy" src="img/facebook.png"><br>
          <span class="text-dark small-text"> Facebook</span>
        </a>

        <a href="https://instagram.com/sportycrash?utm_medium=copy_link&t=" title="Share on Facebook"
          onclick="window.open('https://instagram.com/sportycrash?utm_medium=copy_link' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&t=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
          target="_blank">
          <img loading="lazy" src="img/instagram.png"> <br>
          <span class="text-dark small-text"> Instagram</span>
        </a>

        <a data-action="share/whatsapp/share" target="_blank"
          onclick="window.open('whatsapp://send?text=' + document.getElementById('share-bet-msg').textContent); return false;">
          <img loading="lazy" src="img/whatsapp.png"><br>
          <span class="text-dark small-text"> Whatsapp</span>
        </a>

        <a href="https://twitter.com/SportycrashKe?s=11" target="_blank" title="Tweet"
          onclick="window.open('https://twitter.com/sportycrash?s=11text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
          <img loading="lazy" src="img/twitter.png"><br>
          <span class="text-dark small-text"> Twitter</span>
        </a>

        <a data-action="share/telegram/share" target="_blank"
          onclick="window.open('https://telegram.me/share/url?url=' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
          <img loading="lazy" src="img/telegram.png"><br>
          <span class="text-dark small-text"> Telegram</span>
        </a>

      </div>

      <div class="row p-0 m-0 px-2 mb-2">
        <div class="col-9 px-1">
          <div id="share-bet-link" class="share-betslip-btn" style="color: #FFFFFF">{{ link }}</div>
          <div class="d-none" id="share-bet-code">{{ code }}</div>
          <div class="d-none" id="share-bet-msg">{{ msg }}</div>
        </div>
        <div class="col-3 px-1">
          <button style="background-color: #1c2243;color: white;" class="share-btn" @click="copyShareBetLink">{{
            copyText }}</button>
        </div>
      </div>

    </section>

    <hr class="m-1" style="background-color: black;" />

    <div style="background-color: #E0E0E0;border-top-left-radius: 5px;border-top-right-radius: 5px;" class="text-center slip-ttxt py-3">
      <div class="text-dark mb-3">Want to logout? We'd like you to stay though</div>
      <div>
        <a style="background-color: #1c2243;" @click="logout" class="login-button py-2 px-4 "> <span style="color: white;">Logout</span></a>
      </div>
    </div>


  </div>


</template>

<script>

import wallet from "@/services/wallet";

export default {

  name: 'Setting',
  components: {

  },
  data: function () {
    return {
      amount: 0,
      loading: '',
      withdraw_amount: "",
      inputBackground: '#1c2243',
      new_referral_code: '',
      errors: [],
      code: '',
      link: '',
      msg: '',
      copyText: 'Copy',
      copyBookingCode: 'Click to copy',
      myProfile: this.getProfile(),
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        // react to route changes...
        document.title = "My Sportycrash Account";
        document.description = "Manage Sportycrash account";
      }
    },
    new_referral_code: function (n) {

      console.log('got new value here ' + n);

      this.validateReferralLink();
    },

    withdraw_amount(newValue) {
      if (newValue.trim() !== '') {
        this.inputBackground = 'white';
      } else {
        this.inputBackground = '#1c2243';
      }
    }
  },
  mounted() {

    var vm = this;

    this.$store.dispatch("setCurrentPage", "my-account");
    vm.myProfile = vm.getProfile();

    if (vm.myProfile.id === undefined) {

      this.setError("Login", "Please login to proceed");
      this.$router.push({ name: 'login', params: { action: 'login' } });
      return;
    }

    // setInterval(function () {

    //   vm.myProfile = vm.getProfile();

    // },2000);

    this.code = vm.myProfile.referral_code;
    this.link = process.env.VUE_APP_REFERERRAL_LINK + this.code;

    this.msg = "Hey!! Nabet na Sportycrash. Join me to be a champion, click this link to signup >> \n\n\n\n " + this.link;

  },
  methods: {

    setAmount: function (amount) {

      this.amount = amount
    },
    deposit: function () {

      this.reset();
      var p = this.getProfile();
      if (!p) {

        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: 'login', params: { action: 'login' } });
        return;

      }

      if (this.amount < 1) {

        this.setError("Invalid Amount", "ENter amount atleast 1 KSH or above");
        return;

      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      vm.loading = 'loading';

      wallet.post(path, JSON.stringify(data), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
        .then(res => {

          vm.loading = '';
          var msgs = res.data.message.data;
          vm.setSuccess("Deposit Initiated", msgs);

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            if (parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428) {

              vm.setError("Session Expired", "Your session on this device has expired");
              vm.logout();
              return;
            } else {

              vm.setError("Failed", err.response.data.error_message)
              console.log(JSON.stringify(err.response.data.message));

            }

          } else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          } else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })
    },
    validateReferralLink: function () {

      this.errors = [];

      if (this.new_referral_code.length < 4) {
        this.errors.push('Referral code should be more than 3 characters');
      }

      if (this.new_referral_code.length > 20) {
        this.errors.push('Referral code should be less than 21 characters');
      }

      if (!/^[0-9a-zA-Z]+$/.test(this.new_referral_code)) {
        this.errors.push('Referral code can only contain letters A-Z and numbers 0 - 9');
      }

    },
    withdraw: function () {

      this.reset();
      var p = this.getProfile();
      if (!p) {

        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: 'login', params: { action: 'login' } });
        return;

      }

      if (this.withdraw_amount < 100) {

        this.setError("Invalid Amount", "Enter amount atleast 100 KSH or above");
        return;
      }

      var vm = this;
      var path = "/withdraw";

      var data = {
        amount: parseInt(this.withdraw_amount)
      };

      vm.loading = 'loading';

      wallet.post(path, JSON.stringify(data), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
        .then(res => {

          vm.loading = '';

          console.log(JSON.stringify(res.data, undefined, 2));

          var msgs = res.data.data;
          vm.setSuccess("Withdrawal Initiated", msgs);

        })
        .catch(err => {

          vm.loading = '';

          if (err.response) {

            console.log(JSON.stringify(err.response, undefined, 2));

            if (parseInt(err.response.status) === 401) {

              vm.setError("Session Expired", "Your session on this device has expired");
              //vm.logout();
              return;

            } else {

              vm.setError("Failed", err.response.data.error_message)
              console.log(JSON.stringify(err.response.data.message));
            }

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })
    },
    shareInvite: function () {

      document.getElementById("open-shareinvite").click();

    },
    copyShareBetLink: function () {

      var link = this.link;
      this.copyToClipboard(link);
      this.copyText = 'Copied';

    },
    copyCode: function () {

      var link = "accept#" + this.code;
      this.copyToClipboard(link);
      this.copyBookingCode = 'Invite Code Copied';

    },

  },
  computed: {

    bonus: function () {

      return this.formatCurrency(this.$store.state.bonusBalance);

    },

    bal: function () {

      return this.formatCurrency(this.$store.state.walletBalance);

    },
    profile: function () {

      return this.$store.state.profile;

    },
    has_referral_code: function () {

      return this.code !== undefined && this.code.length > 3;

    },

  },
  filters: {
    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
}
</script>